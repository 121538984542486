<template>
  <div
    :class="['box', $root.config.theme]"
    :style="[{ '--left-max-width': leftMaxWidth + 'px' }]"
  >
    <div class="left" id="left">
      <div class="bar">
        <div class="bar-border"></div>
      </div>
      <div class="text">
        <div class="document-container">
          <div class="user-container">
            <el-image :src="logoUrl" class="user-container-head"></el-image>
          </div>
          <div class="document-list" :style="{ height: listHeight }">
            <el-scrollbar
              ref="scroll"
              :height="scrollerHeight"
              class="scroll-box"
            >
              <el-tree
                id="treeBox"
                class="document-list-elm"
                ref="tree"
                node-key="id"
                :key="treeKey"
                :data="nodesList"
                :empty-text="emptyText"
                :props="defaultProps"
                :draggable="false"
                :expand-on-click-node="false"
                :check-on-click-node="true"
                :highlight-current="true"
                :current-node-key="articleId"
              >
                <!-- @node-contextmenu="rightClick" -->
                <template #default="{ node, data }">
                  <div class="custom-tree-node-box">
                    <div class="custom-tree-node">
                      <div v-if="data.iconType == 0">
                        <svg-icon
                          v-if="node.isCurrent == true"
                          icon-class="apple_s"
                          class-name="apple"
                        ></svg-icon>
                        <svg-icon
                          v-else
                          icon-class="apple"
                          class-name="apple"
                        ></svg-icon>
                        <span
                          :class="[
                            {
                              'red-color':
                                data.iconType == 1 && node.isCurrent == true,
                            },
                          ]"
                          style="line-height: 33px"
                          >{{ node.label }}</span
                        >
                      </div>
                      <div v-else-if="data.iconType == 1">
                        <svg-icon
                          icon-class="appleRed_s"
                          class-name="apple"
                        ></svg-icon>
                        <span
                          :class="[
                            {
                              'red-color':
                                data.iconType == 1 && node.isCurrent == true,
                            },
                          ]"
                          style="line-height: 33px"
                          >{{ node.label }}</span
                        >
                      </div>
                      <div v-else-if="data.iconType == 2">
                        <svg-icon
                          icon-class="tree_l_dark"
                          class-name="tree-forest"
                        ></svg-icon>
                        <span
                          :class="[
                            {
                              'red-color':
                                data.iconType == 1 && node.isCurrent == true,
                            },
                          ]"
                          style="line-height: 33px"
                          >{{ node.label }}</span
                        >
                      </div>

                      <div v-else-if="data.iconType == 20">
                        <svg-icon
                          v-if="node.isCurrent == true"
                          icon-class="icon-tree_s"
                          class-name="icon-tree"
                        >
                        </svg-icon>
                        <svg-icon
                          v-else-if="node.isCurrent != true"
                          icon-class="icon-tree"
                          class-name="icon-tree"
                        >
                        </svg-icon>
                        <span
                          :class="[
                            {
                              'red-color':
                                data.iconType == 1 && node.isCurrent == true,
                            },
                          ]"
                          style="line-height: 33px"
                          >{{ node.label }}</span
                        >
                      </div>
                      <div v-else-if="data.iconType == 99">
                        <svg-icon
                          v-if="node.isCurrent == true"
                          icon-class="tree_s"
                          class-name="tree"
                        ></svg-icon>
                        <svg-icon
                          v-else
                          icon-class="tree"
                          class-name="tree"
                        ></svg-icon>
                        <span
                          :class="[
                            {
                              'red-color':
                                data.iconType == 1 && node.isCurrent == true,
                            },
                          ]"
                          style="line-height: 33px"
                          >{{ node.label }}</span
                        >
                      </div>
                      <div v-else>
                        <svg-icon
                          v-if="node.level == 1 && node.isCurrent == true"
                          icon-class="tree_s"
                          class-name="tree"
                        ></svg-icon>
                        <svg-icon
                          v-else-if="node.level == 1 && node.isCurrent != true"
                          icon-class="tree"
                          class-name="tree"
                        ></svg-icon>
                        <svg-icon
                          v-else-if="node.level > 1 && node.isCurrent == true"
                          icon-class="apple_s"
                          class-name="apple"
                        ></svg-icon>
                        <svg-icon
                          v-else
                          icon-class="apple"
                          class-name="apple"
                        ></svg-icon>
                        <span
                          :class="[
                            {
                              'red-color':
                                data.iconType == 1 && node.isCurrent == true,
                            },
                          ]"
                          style="line-height: 33px"
                          >{{ node.label }}</span
                        >
                      </div>
                    </div>
                  </div>
                </template>
              </el-tree>
              <div style="width: 100%; height: 50px"></div>
            </el-scrollbar>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="header">
        <div class="header-left">
          <el-breadcrumb separator="〉" class="right-breadcrumb">
            <el-breadcrumb-item>
              <svg-icon icon-class="tree_b" class-name="tree_b"></svg-icon>
              我的模型树
            </el-breadcrumb-item>
            <el-breadcrumb-item>{{ nodesList[0].name }}</el-breadcrumb-item>
            <el-breadcrumb-item>段落记录</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="header-right">
          <el-button class="top-right-btn" @click="backBtn" style="width: auto">
            <el-icon>
              <Back />
            </el-icon>
            返回主页
          </el-button>
          <el-button class="top-right-btn" @click="fullScreen">
            <el-icon>
              <FullScreen />
            </el-icon>
          </el-button>
          <!-- <el-divider direction="vertical"></el-divider> -->
          <div class="right-avatar">
            <el-dropdown
              v-if="isLogin"
              trigger="click"
              :popper-class="[$root.config.theme]"
            >
              <div @click="toLogin">
                <el-avatar
                  :size="32"
                  fit="fill"
                  src="/pc/avatar.png"
                  icon="UserFilled"
                  style="background: #1380ff; border: 1px solid #c4c4c4"
                >
                </el-avatar>
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="openConfig('bindAccount')">
                    <el-icon>
                      <Setting />
                    </el-icon>
                    <span>设置</span>
                  </el-dropdown-item>
                  <el-dropdown-item @click="outLogin">
                    <el-icon>
                      <SwitchButton />
                    </el-icon>
                    <span>退出</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <div v-else @click="toLogin">
              <el-avatar
                :size="24"
                fit="fill"
                src="/pc/avatar.png"
                icon="UserFilled"
                style="background: #1380ff"
              >
              </el-avatar>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <paragraph-history
          :articleId="articleId"
          :getTitle="getTitle"
        ></paragraph-history>
      </div>
    </div>
  </div>
  <div v-if="configVisible" :class="['set-config', $root.config.theme]">
    <div class="config-box">
      <set-config
        ref="setConfig"
        :configActive="configActive"
        :closeConfig="closeConfig"
        :editorRefresh="editorRefresh"
      ></set-config>
    </div>
  </div>
</template>
<script>
import setConfig from "@/views/pc/article/set-config";
import paragraphHistory from "@/views/pc/article/history/paragraph";
import screenfull from "screenfull";
import { defaultTheme } from "../../../../../utils/constaints";

export default {
  components: {
    setConfig,
    paragraphHistory,
  },
  data() {
    return {
      articleId: "",
      pageIndex: 1,
      logoUrl: "/pc/logo-dark.png",
      leftMaxWidth: 800,
      listHeight: 0,
      scrollerHeight: 0,
      treeKey: 0,
      isLazy: true,
      emptyText: "加载中",
      defaultProps: { label: "name", isLeaf: "hasChildren" },
      nodesList: [
        {
          id: null,
          name: "",
          hasChildren: false,
          children: [],
          iconType: 99,
        },
      ],
      isLogin: "false",
      configVisible: false,
      configActive: "config",
    };
  },
  inject: ["reload"],
  watch: {
    "$root.config": {
      handler(val, old) {
        if (val !== old) {
          this.changeConfig();
        }
      },
    },
  },
  created() {
    // 判断路由是否包含节点ID
    if (this.$route.params.articleId) {
      this.articleId = this.$route.params.articleId;
    }
    this.isLogin = localStorage.getItem("isLogin");
    if (this.isLogin == "true") {
      this.changeConfig();
    }
  },
  mounted() {
    this.listHeight = window.innerHeight - 102 + "px";
    this.scrollerHeight = window.innerHeight - 160 + "px";
    this.nodesList[0].id = this.articleId;
    // this.getParagraphHistory();
  },
  methods: {
    changeConfig() {
      // console.log(config);
      if (this.$root.config.theme) {
        if (this.$root.config.theme.includes("dark")) {
          // 暗色只有dark3 且是默认
          this.$root.config.theme = defaultTheme;
          this.logoUrl = "/pc/logo-dark.png";
        } else {
          this.logoUrl = "/pc/logo-default.png";
        }
        this.theme = this.$root.config.theme;
      }
    },
    refresh() {
      this.reload();
    },
    getParagraphHistory() {
      // console.log(this.articleId, this.pageIndex);
      this.$api.historyArticle
        .paragraphHistoryByPage(this.articleId, this.pageIndex)
        .then((res) => {
          if (res.data.list.length > 0) {
            this.nodesList[0].id = this.articleId;
            this.nodesList[0].name = res.data.list[0].topArticleName;
          }
        });
    },
    fullScreen() {
      screenfull.toggle();
    },
    // 登录
    toLogin() {
      if (localStorage.getItem("isLogin")) {
        // this.$message.success("您已登录");
      } else {
        this.$router.push("/login");
      }
    },
    outLogin() {
      this.$store.commit("delToken");
      this.isLogin = false;
      this.$router.push("/login");
    },
    // 打开设置页面
    openConfig(configActive = "bindAccount") {
      this.configActive = configActive;
      this.configVisible = true;
    },
    closeConfig() {
      this.refresh();
    },
    backBtn() {
      if (this.isLogin == "true") {
        this.$router.push("/article");
      } else {
        this.$router.push("/login");
      }
    },
    getTitle(title) {
      this.nodesList[0].name = title;
    },
  },
};
</script>
<style lang="scss" scoped>
html,
body,
div.box {
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

div {
  box-sizing: border-box;
}

div.box {
  display: flex;
}

div.left {
  position: relative;
  height: 100%;
  // outline: 1px solid #fff;
}

div.content {
  flex: 1;
  height: 100%;
  padding: 0 0 0 2px;
}

/* 侧边栏文本样式 */
div.text {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  // background: #ffffff;
  // border-right: 1px solid #e9e9e9;
}

/*拖动按钮样式*/
/*拖动bar盒时，父级盒side会被bar盒撑开，又因为text盒的宽高都设为100%，所以改变bar盒就是在改变text盒*/
div.bar {
  width: 300px;
  height: 100vh;
  margin-right: -2px;
  /* 因为bar盒子和text盒子是重合的所以必须设置一个负值才能看到我们的拖动按钮*/
  overflow: scroll;
  resize: horizontal;
  /*水平拖放*/
  min-width: 200px;
  max-width: 600px;
  cursor: e-resize;
}

.bar-btn {
  cursor: e-resize;
}

.bar-border {
  position: absolute;
  width: 5px;
  top: 0;
  right: 0;
  height: 100%;
  // border-right: 1px solid #e9e9e9;
  cursor: e-resize;
  z-index: 2;
}

/*因为拖动按钮属于滚动条系列，所以可通过改变滚动来间接改变拖动按钮的样式*/
div.bar::-webkit-scrollbar {
  width: 10px;
  height: 9999px;
}

.header-left {
  float: left;
  height: 64px;
}

.flex-s {
  flex-shrink: 0;
}

.flex-g {
  flex-grow: 1;
  min-width: 20%;
}

.pointer {
  cursor: pointer;
}

.container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  background-color: #ffffff;

  .left {
    flex-shrink: 0;
    width: 260px;
    max-width: 260px;
    background: #f4f4f5;
    mix-blend-mode: normal;
  }

  .right {
    min-width: 50%;
    flex-grow: 1;
    height: 100%;
  }
}

.mind-map {
  width: 100%;
  box-sizing: border-box;
  // background: #eeeef3;
}

.recycle-bin {
  width: 100%;
  box-sizing: border-box;
  background: #eeeef3;
}

.right-header {
  height: 64px;
}

.left-footer {
  padding: 0;
}

.user-container {
  display: flex;
  align-items: center;
  // justify-content: center;
  height: 70px;
  padding: 5px 75px;

  .user-container-head {
    @extend .flex-s;
    width: 123px;
    height: 40px;
  }

  .user-container-nick {
    @extend .flex-g;
    padding: 0 10px;
    color: #109adc;
    font-size: 20px;
  }

  .user-container-msgBtn {
    @extend .pointer;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    border-radius: 6px;

    &:hover {
      background-color: rgba(#000, 0.04);
    }
  }
}

.right-main {
  padding: 0;
}

.search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px 5px 15px;
  border-bottom: 1px solid #eee;

  .search-bar-input {
    @extend .flex-g;
    height: 32px;
    font-size: 14px;
    padding: 10px;
    background-color: rgb(233, 233, 235);
    border: none;
    border-radius: 6px;
    box-sizing: border-box;
  }

  .search-bar-btn {
    text-align: center;
    line-height: 40px;
  }
}

.item-bar {
  display: flex;
  align-items: center;
  // justify-content: center;
  padding: 0 15px 0px 15px;
  border-bottom: 1px solid #eee;

  .item-bar-btn {
    position: relative;
    display: inline-flex;
    width: auto;
    min-width: 0px;
    height: 26px;
    box-sizing: border-box;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0px 6px;
    border: none;
    background-color: rgb(244, 244, 245);
    border-radius: 6px;
    color: rgb(117, 117, 125);
    cursor: pointer;
    font-size: 14px;
    outline: none;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.2s ease 0s, color 0.2s ease 0s,
      box-shadow 0.2s ease 0s, border 0.2s ease 0s;
    white-space: nowrap;
    margin-right: 10px;
  }
}

.custom-tree-node-svg {
  vertical-align: middle;
  margin-bottom: 3px;
  margin-right: 5px;
}

.document-list {
  padding: 5px 0;
}

.document-column {
  @extend .pointer;
  display: flex;
  align-items: center;
  margin: 0 10px 5px;
  height: 36px;
  // background: #f4f4f4;
  mix-blend-mode: normal;
  border-radius: 8px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }

  &:hover,
  &[active] {
    // background: #f4f4f4;
    mix-blend-mode: normal;
    border-radius: 8px;
  }

  .document-column-icon {
    @extend .flex-s;
    margin-left: 10px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    color: #1380ff;
  }

  .document-column-name {
    @extend .flex-g;
    font-size: 14px;
    padding: 0 5px;
    // color: #4d627a;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .document-column-methods {
    @extend .flex-s;
    margin-right: 12px;
    margin-top: 4px;

    .document-column-methods-item {
      width: 24px;
      height: 24px;
      line-height: 24px;
      border-radius: 6px;
      text-align: center;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
}

.document-list-elm {
  background-color: transparent;
  font-size: 9px;
  // color: #4d627a;
  margin: 0 10px;
  padding-bottom: 40px;

  .custom-tree-node-box {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    height: 36px;

    .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      padding-right: 8px;
      height: 36px;
    }

    .custom-tree-node-methods {
      display: none;
      line-height: 36px;
    }

    &:hover {
      // .custom-tree-node {
      //   color: #1380ff;
      // }
      // background: #f2f2f2;
      border-radius: 4px;

      .custom-tree-node-methods {
        display: block;
      }
    }
  }
}

:deep .el-tree__drop-indicator {
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #1380ff !important;
}

:deep .el-tree-node.is-drop-inner > .el-tree-node__content {
  box-sizing: border-box;
  border: 1px dashed #1380ff;
  border-radius: 4px;
}

:deep .el-tree-node__content > .el-tree-node__expand-icon {
  margin: 0;
  padding: 6px;
  -webkit-box-sizing: initial;
  -moz-box-sizing: initial;
  box-sizing: initial;
}

.document-elm-menu {
  > *:nth-child(2) {
    display: inline-block;
    margin-left: 10px;
  }
}

.document-btn {
  font-size: 12px;
  padding-left: 4px;
  margin-left: 8px !important;
  padding-top: 6px;
}

.data-input {
  width: 80%;
}

.el-input__inner::placeholder {
  color: #333333;
}

.el-input__inner::-webkit-input-placeholder {
  color: #333333;
  text-align: center;
}

.el-input__inner:-moz-placeholder {
  color: #333333;
}

.el-icon-plus-blue {
  color: #1380ff;
}

:deep .el-breadcrumb__separator {
  margin: -1px 0 0 5px;
  font-weight: 500;
  font-size: 12px;
  color: #6c6c6c;
  display: inline-block;
  vertical-align: top;
}

.button-group {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom: 0px;
}

.right-breadcrumb {
  font-size: 12px;
  color: #1f2329;
  margin-left: 20px;
  margin-top: 24px;
  max-width: 500px;
  line-height: 16px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.right-refresh {
  // color: #666666;
  font-size: 10px;
  margin-left: 20px;
  margin-top: 5px;
}

.refreshactive {
  color: #1380ff;
}

:deep .el-divider--vertical {
  margin: 0 8px 0 8px !important;
}

#mindmap {
  height: 100%;
}

.treeactive {
  background: url("~@/assets/icon-tree-b.png") no-repeat center center;
  background-size: 100%;
}

.bookactive {
  background: url("~@/assets/icon-book-b.png") no-repeat center center;
  background-size: 100%;
}

.active {
  color: #1380ff;
}

.icon-color {
  color: #666666;
}

.mind-map-box {
  float: left;
  width: 100%;
}

.smaller {
  // width: calc(70% - 10px) !important;
  overflow: hidden;
}

.el-dropdown-menu__item--divided:before {
  margin: 0 !important;
}

.el-dropdown-menu__item--divided {
  margin: 0 !important;
}

.el-dropdown-menu {
  padding: 5px !important;
}

.over-content {
  max-width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.scroll-box {
  transform-origin: left bottom;
  transform: translate(0px, 0px) scale(1);
  transition: all 0.5s ease 0.5s;
}

.dropdown {
  vertical-align: middle;
}

.move-dialog {
  :deep .el-dialog {
    border-radius: 12px;

    .el-dialog__body {
      padding: 0 20px;
    }
  }
}

.offer-dialog {
  :deep .el-dialog {
    border-radius: 12px;

    .el-dialog__body {
      padding: 0 20px 20px 20px;
    }
  }
}

.red-color {
  color: #de4e4e !important;
}

:deep .fr-box.fr-basic .fr-element {
  padding: 0;
  color: #000;
}

.resize-bar {
  width: var(--left-max-width);
  max-width: var(--left-max-width);
}

.resize-bar::-webkit-scrollbar {
  width: var(--left-max-width);
  height: inherit;
}

.help-box {
  position: fixed;
  right: 20px;
  bottom: 40px;
  z-index: 2000;
}

:deep .el-message .el-message__badge {
  display: none !important;
}

:deep .el-badge.el-message__badge {
  display: none !important;
}
</style>
